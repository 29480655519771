import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import "./lecture.scss";
import Loader from "../utils/Loader.js";

import {
  updateQuizStatus,
  pluginIdentifier,
} from "../../actions/quizAction.js";
import { updateAssignmentStatus } from "../../actions/assignmentAction.js";
import { getSignedUrl } from "../../actions/docLectureActions.js";
import { template } from "lodash";
import {
  deleteLectureBookmark,
  getChapterBookmark,
  getLectureBookmark,
  getLectureTime,
  submitIvq,
  updateLecStatus,
  updateLectureBookmark,
  updateLivLecStatus,
} from "../../actions/lectureAction.js";
import ReactPlayer from "react-player";
import { downloadBase64File } from "../../utility/utils.js";
import {
  endUserQuiz,
  initializeUserQuiz,
  registerUserQuizIdnt,
  testQuestion,
  testToken,
} from "../../actions/batchAction.js";
import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../../constants/routes.js";
import { CONFIGS } from "../../constants/constants.js";
const cookies = new Cookies();

const Lecture = ({
  course,
  status,
  getIndex,
  prevFid,
  setContentLoading,
  setNextButtonLoading,
}) => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const cid = query[6];
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  const type = query[8];
  const chid = query[9];
  const fid = query[10];
  const timeSkip = query[11];
  console.log(query[11]);

  const [loading, setLoading] = useState(true);
  const [lecture, setLecture] = useState();
  const [isLockedDeadline, setIsLockedDeadline] = useState(false);
  const [deadline, setDeadline] = useState();
  const [gstatus, setGstatus] = useState();
  const [feedbackResponse, setFeedbackResponse] = useState();
  const [feedbackGstatus, setFeedbackGstatus] = useState();
  const [feedbackGstatusValue, setFeedbackGstatusValue] = useState();
  const [feedbackLecture, setFeedbackLecture] = useState();
  const [descriptionAsHtml, setDescriptionAsHtml] = useState();
  const [liveData, setLiveData] = useState();
  const [lectureUrl, setLectureUrl] = useState();
  const [videoSource, setVideoSource] = useState();
  const [feedbackError, setFeedbackError] = useState();
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState();
  const [feedbackOpen, setFeedbackOpen] = useState();
  const [playedSeconds, setPlayedSeconds] = useState();
  const [played, setPlayed] = useState();
  const [unsaveData, setUnsaveData] = useState(true);
  const [addNewBk, setAddNewBk] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [bkmrkTextArea, setBkmrkTextArea] = useState("");
  const [aboutResources, setAboutResources] = useState("About");
  const [resourceMap, setResourceMap] = useState();
  const [bkmrkFilter, setBkmrkFilter] = useState("clc");
  const [bkmrkSort, setBkmrkSort] = useState("smr");
  const [lectureBookmark, setLectureBookmark] = useState([]);
  const [selectedLectureBookmark, setSelectedLectureBookmark] = useState([]);
  const [chapterBookmark, setChapterBookmark] = useState([]);
  const [selectedBkIndex, setSelectedBkIndex] = useState(-1);
  const [openDeleteBkModal, setOpenDeleteBkModal] = useState(false);
  const [editBk, setEditBk] = useState(false);
  const [timeMap, setTimeMap] = useState({});
  const [IVQShow, setIVQShow] = useState(false);
  const [submittedIvq, setSubmittedIvq] = useState({});
  const [quesData, setQuesData] = useState({});
  const [selected, setSelected] = useState();
  const [isCorrected, setIsCorrected] = useState();
  const [correctOpt, setCorrectOpt] = useState([]);
  const [showExplanation, setShowExplanation] = useState(false);
  const [skippedFeedback, setSkippedFeedback] = useState(false);
  const [feedbackTestId, setFeedbackTestId] = useState();
  const [prevIntervalCall, setPrevIntervalCall] = useState(
    status?.status[fid]?.vres_tim || 5
  );
  const [intervalCall, setIntervalCall] = useState(0);
  const [uIdntFeedback, setUIdntFeedback] = useState();
  const [idnt_id, setIdnt_id] = useState("");
  const [srvId, setSrvId] = useState();
  const [quizUserId, setQuizUserId] = useState();
  const [feedbackGCode, setFeedbackGCode] = useState();
  const [uIdntFeedback2, setUIdntFeedback2] = useState();
  const [quizToken, setQuizToken] = useState();
  const [userTestId, setUserTestId] = useState();
  const [feedbackSections, setFeedbackSections] = useState();
  const [feedbackLoaded, setFeedbackLoaded] = useState();
  const [averageRating, setAverageRating] = useState();
  const [answerOptionMap, setAnswerOptionMap] = useState({});
  const [fullFeedbackAttempted, setFullFeedbackAttempted] = useState();
  const [subjectiveFeedbackAnswerMap, setSubjectiveFeedbackAnswerMap] =
    useState({});
  const [previousBookmarkNote, setPreviousBookmarkNote] = useState("");
  const [videoInqueStatus, setVideoInqueStatus] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState();
  const [videoId, setVideoId] = useState("");
  const [bunnyUploadingText, setBunnyUploadingText] = useState("");
  const [bunnyUploadStatus, setBunnyUploadStatus] = useState();
  const [isLectureCompleted, setIsLectureCompleted] = useState(false);

  const handletoggle = () => {
    setToggle(!toggle);
  };

  const playerRef = useRef(null);
  const playedSecondsRef = useRef(playedSeconds);

  useEffect(() => {
    (async () => {
      let ifLecture = course?.lectures[fid];
      console.log("ifLecture", ifLecture);

      if (!ifLecture) {
        if (
          (!lecture.feedback && played >= 0.8) ||
          (lecture.feedback && !lecture.feedback_compulsory && played >= 0.8) ||
          (lecture.feedback &&
            lecture.feedback_compulsory &&
            isFeedbackSubmitted &&
            played >= 0.8)
        ) {
          await updateLectureStatus(false, true, playedSeconds);
          if (!isLectureCompleted) {
            setIsLectureCompleted(true);
            setNextButtonLoading(true);
          }
        } else {
          await updateLectureStatus(false, false, playedSeconds);
        }
      } else {
        if (
          (!lecture.feedback && played >= 0.8) ||
          (lecture.feedback && !lecture.feedback_compulsory && played >= 0.8) ||
          (lecture.feedback &&
            lecture.feedback_compulsory &&
            isFeedbackSubmitted &&
            played >= 0.8)
        ) {
          await updateLectureStatus(true, true, playedSeconds);
          if (!isLectureCompleted) {
            setIsLectureCompleted(true);
            setNextButtonLoading(true);
          }
        } else {
          await updateLectureStatus(true, false, playedSeconds);
        }
      }
    })();
  }, [intervalCall]);
  useEffect(() => {
    console.log({ lecture });
  }, [lecture]);
  useEffect(() => {
    console.log({ lecture });
  }, [lectureUrl]);

  useEffect(() => {
    (async () => {
      let ifLecture = course?.lectures[fid];
      if (ifLecture) {
        let data = await updateLecStatus(pid, cid, chid, fid, 0, false);
        console.log({ data });
        if (
          data &&
          data.data &&
          data.data.url &&
          data.data.url.includes("b-cdn.net")
        ) {
          setVideoSource("b-cdn.net");
          console.log("video-url", data.data.url);
          setVideoId(data.data.videoId);
          setLectureUrl(data.data.url);
        }
        if (data && data.data && data.data.bunny_upload_status) {
          setBunnyUploadStatus(data.data.bunny_upload_status);
        }

        if (
          (data.data.showBunny &&
            data.data.bunny_upload_status != 3 &&
            data.data.url &&
            !data.data.url.includes("b-cdn.net")) ||
          (data.data.showBunny && data.data.encodingStatus != 4)
        ) {
          setVideoInqueStatus(true);
        }
        if (data && data.data && !data.data.url) {
          setVideoInqueStatus(true);
        }
        if (data && data.data && data.data.uploadingText) {
          setBunnyUploadingText(data.data.uploadingText);
        }
      } else {
        let data = await updateLivLecStatus(pid, cid, chid, fid, 0, false);
        console.log(data.data);
        if (
          data &&
          data.data &&
          data.data.live_lecture &&
          data.data.live_lecture.bunny_upload_status
        ) {
          setBunnyUploadStatus(data.data.live_lecture.bunny_upload_status);
        }
        if (
          data &&
          data.data &&
          data.data.live_lecture &&
          data.data.live_lecture.recurl &&
          data.data.live_lecture.recurl.includes("b-cdn.net")
        ) {
          setVideoId(data.data.live_lecture.videoId);

          setLectureUrl(data.data.live_lecture.recurl);
          setVideoSource("b-cdn.net");
        }
        if (
          (data &&
            data.data &&
            data.data.live_lecture.showBunny &&
            data.data.live_lecture.bunny_upload_status != 3 &&
            data.data.live_lecture.recurl &&
            !data.data.live_lecture.recurl.includes("b-cdn.net")) ||
          (data.data.live_lecture.showBunny &&
            data.data.live_lecture.encodingStatus != 4)
        ) {
          setVideoInqueStatus(true);
        }

        if (
          data &&
          data.data &&
          data.data.live_lecture &&
          !data.data.live_lecture.recurl
        ) {
          setVideoInqueStatus(true);
        }

        if (
          data &&
          data.data &&
          data.data.live_lecture &&
          data.data.live_lecture.uploadingText
        ) {
          setBunnyUploadingText(data.data.live_lecture.uploadingText);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (showExplanation || openDeleteBkModal) {
      document.body.style.overflow = "hidden";
    }
  }, [showExplanation, openDeleteBkModal]);
  useEffect(() => {
    (async () => {
      if (course && status && type) {
        setContentLoading(true);
        setLoading(true);
        setIVQShow(false);
        setTimeMap({});
        await getIndex();
        var tempLectureData = course?.lectures[fid];
        if (
          tempLectureData &&
          tempLectureData.etim &&
          tempLectureData.etim != ""
        ) {
          var tempIsLockedDeadline =
            parseInt(tempLectureData.etim) < new Date().getTime()
              ? true
              : false;
          var tempFormattedDate = new Date(tempLectureData.etim).toLocaleString(
            "en-US",
            {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }
          );
          setIsLockedDeadline(tempIsLockedDeadline);
          setDeadline(tempFormattedDate);
        }
        setGstatus(status.status);
        setFeedbackResponse(
          status.feedbackStatics.FEEDBACK_SUBMISSION_RESPONSE
        );
        setFeedbackGstatus(status.status);
        setFeedbackGstatusValue(status.status_type_value);
        await activateLecture();
      }
      if (course && status && !type) {
        setContentLoading(true);
        setLoading(true);
        setIVQShow(false);
        setTimeMap({});
        await getIndex();
        var tempLectureData = course?.lectures[fid];
        if (
          tempLectureData &&
          tempLectureData.etim &&
          tempLectureData.etim != ""
        ) {
          var tempIsLockedDeadline =
            parseInt(tempLectureData.etim) < new Date().getTime()
              ? true
              : false;
          var tempFormattedDate = new Date(tempLectureData.etim).toLocaleString(
            "en-US",
            {
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }
          );
          setIsLockedDeadline(tempIsLockedDeadline);
          setDeadline(tempFormattedDate);
        }
        setGstatus(status.status);
        setFeedbackResponse(
          status.feedbackStatics.FEEDBACK_SUBMISSION_RESPONSE
        );
        setFeedbackGstatus(status.status);
        setFeedbackGstatusValue(status.status_type_value);
        await activateLecture();
      }
      console.log({ tempLectureData });
      await handleBookMark();
      setLoading(false);
      setContentLoading(false);
    })();
  }, [prevFid]);

  const openFeedback = async function (quiz, chapId) {
    setFeedbackTestId(quiz.tid);
    if (quiz._id != feedbackLecture._id) {
      return;
    }
    var currentQuiz = feedbackLecture;
    if (!currentQuiz["completion"]) {
      const pluginData = await get_plugin_identifier(currentQuiz, gid);
    }
  };

  const get_plugin_identifier = async function (quiz, gid) {
    pluginIdentifier(quiz, gid, {})
      .then(function (res) {
        // console.log({get_plugin_identifierres: res})
        if (res.data && res.data.success && res.data.data) {
          setUIdntFeedback(() => {
            return res.data.data.u_idnt;
          });
          setIdnt_id(res.data.data.idnt_id);
          console.log({ u_idntFeedback: uIdntFeedback });
          registerUserQuiz(quiz, res.data.data.u_idnt, res.data.data.idnt_id);
        } else {
          console.log("user doesn't have access for this quiz");
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const registerUserQuiz = async function (quiz, uIdntFeedback, idntId) {
    console.log({ quiz: quiz });
    registerUserQuizIdnt(quiz, uIdntFeedback, idntId).then(
      function (data) {
        if (!data.config) {
          console.log("Server error occured.");
        }
        console.log({ registerUserQuizdata: data });
        if (data.data.data.srv_id && data.data.data.id) {
          setSrvId(() => {
            return data.data.data.srv_id;
          });
          setQuizUserId(() => {
            return data.data.data.id;
          });
          console.log({ srv_id: srvId });
          setFeedbackGCode(() => {
            return data.data.data.gid;
          });
          initializeQuiz(
            quiz,
            uIdntFeedback,
            data.data.data.srv_id,
            data.data.data.id,
            data.data.data.gid,
            idntId
          );
        }

        // deferred.resolve(data);
      },
      function (error) {
        // deferred.reject(error);
        console.log(error);
        console.log("user doesn't have access for this quiz");
      }
    );
  };

  const initializeQuiz = async function (
    quiz,
    uIdntFeedback,
    srvId,
    quizUserId,
    feedbackGCode,
    idntId
  ) {
    console.log({ quiz: quiz });
    initializeUserQuiz(
      quiz,
      uIdntFeedback,
      srvId,
      quizUserId,
      feedbackGCode,
      {
        plugin_id: quiz.plid,
      },
      idntId
    ).then(
      function (data) {
        if (!data.config) {
          console.log("Server error occured.");
        }
        console.log({ initializeQuizdata: data });
        if (data.data.success && data.data.data.at)
          setUIdntFeedback2(() => {
            return data.data.data.at;
          });
        getTestToken(
          quiz,
          data.data.data.at,
          quizUserId,
          feedbackGCode,
          idntId
        );
      },
      function (error) {
        console.log(error);
        console.log("user doesn't have access for this quiz");
      }
    );
  };

  const getTestToken = async function (
    quiz,
    uIdntFeedback2,
    quizUserId,
    feedbackGCode,
    idntId
  ) {
    console.log({ quiz: quiz });
    testToken(
      quiz,
      uIdntFeedback2,
      quizUserId,
      feedbackGCode,
      {
        plugin_id: quiz.plid,
      },
      idntId
    ).then(
      function (data) {
        if (!data.config) {
          console.log("Server error occured.");
        }
        console.log({ getTestToken: data });
        if (data.data.data.test_at) {
          setQuizToken(() => {
            return data.data.data.test_at;
          });
          console.log({ quizToken: data.data.data.test_at });
        }
        if (data.data.data.id) {
          setUserTestId(() => {
            return data.data.data.id;
          });
        }
        getTestQuestions(
          quiz,
          feedbackGCode,
          data.data.data.test_at,
          uIdntFeedback2,
          quizUserId,
          data.data.data.id,
          idntId
        );
      },
      function (error) {
        console.log(error);
        console.log("user doesn't have access for this quiz");
      }
    );
  };

  const getTestQuestions = async function (
    quiz,
    feedbackGCode,
    quizToken,
    uIdntFeedback2,
    quizUserId,
    userTestId,
    idntId
  ) {
    console.log({ quiz: quiz });
    testQuestion(
      quiz,
      feedbackGCode,
      quizToken,
      uIdntFeedback2,
      quizUserId,
      userTestId,
      { plugin_id: quiz.plid },
      idntId
    ).then(
      function (data) {
        if (!data.config) {
          console.log("Server error occured.");
        }
        console.log({ getTestQuestions: data.data.data.sectionsFound });
        if (data.data.data.sectionsFound) {
          setFeedbackSections(() => {
            return [...data.data.data.sectionsFound];
          });
          console.log({ feedbackSections: feedbackSections });
          // angular.element('#feedbacksContainer')[0].scrollTop = 0
          // console.log({
          //     scroll: angular.element('#feedbacksContainer')[0].scrollTop,
          // })

          var feed_ques = data.data.data.sectionsFound[0].ques;
          console.log({ ques: feed_ques });

          if (feed_ques.length > 0) {
            console.log();
            var tempAnswerOptionMap = {};
            for (var i = 0; i < feed_ques.length; i++) {
              tempAnswerOptionMap[feed_ques[i].question._id] = 0;
            }
            setAnswerOptionMap({ ...tempAnswerOptionMap });
          }
          setFeedbackLoaded(true);
          setFeedbackOpen(true);
        }
      },
      function (error) {
        console.log(error);
        console.log("user doesn't have access for this quiz");
      }
    );
  };

  const submitFeedback = async function () {
    let check = checkAllFilled();
    if (check) {
      var answers = [];
      let Average_rating = 0;
      var count = 0;
      for (var i = 0; i < feedbackSections.length; i++) {
        var section = feedbackSections[i];
        for (var j = 0; j < section.ques.length; j++) {
          var ques = section.ques[j];
          var ans = { q: ques.question._id, t: 1 };
          if (ques.question.answer) {
            if (ques.question.type == 1 || ques.question.type == 4) {
              ans["a"] = ques.question.answer.index;
              if (ques.question.type == 4) {
                Average_rating +=
                  ques.question.answer.opt_point /
                  ques.question.ans_options.length;

                ans["a"] = ques.question.answer.index;
                console.log(Average_rating);
              }
            }
          } else if (ques.question.type == 3) {
            console.log(subjectiveFeedbackAnswerMap[ques.question._id]);
            ans["a"] = subjectiveFeedbackAnswerMap[ques.question._id];
          }
          if (ans) {
            answers.push(ans);
          }
          count++;
        }
      }

      setAverageRating(
        Math.round((Average_rating / count) * feedbackResponse.length)
      );
      var testId = feedbackSections[0].testId;

      var url = ROUTES.END_V2;
      var testId = feedbackSections[0].testId;

      const endQuiz = await endUserQuiz(
        answers,
        feedbackGCode,
        { plugin_id: feedbackLecture.plid },
        quizToken,
        feedbackLecture,
        uIdntFeedback2,
        quizUserId,
        userTestId,
        idnt_id
      );
      if (endQuiz.data.success) {
        await completeFeedback(feedbackLecture, feedbackLecture.tid);
      }
    } else {
      window.alert("Please fill all the answers for the feedback");
    }
  };

  const completeFeedback = async (quiz, testId) => {
    var chapter = quiz.chap;
    var url;
    if (!liveData) {
      url = ROUTES.COMPLETE_FEEDBACK_LECTURE;
    } else {
      url = ROUTES.COMPLETE_FEEDBACK_LIVE_LECTURE;
    }
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    axios
      .post(
        url,
        {
          aid: cookies.get("aid"),
          pid: pid,
          feedbackId: quiz._id,
          cid: cid,
          chpId: chid,
          user_id: quizUserId,
          user_test_id: userTestId,
          lec_id: fid,
          cl: "W",
        },
        configs
      )
      .then(async function (res) {
        setIsFeedbackSubmitted(true);
        let ifLecture = course?.lectures[fid];
        if (!ifLecture) {
          await updateLectureStatus(false, true, playedSeconds);
        } else {
          await updateLectureStatus(true, true, playedSeconds);
        }
        if (!isLectureCompleted) {
          setIsLectureCompleted(true);
          setNextButtonLoading(true);
        }
      })
      .catch(function (err) {
        setFeedbackError(true);
      });
  };

  const activateLecture = async () => {
    setLecture(course?.lectures[fid]);
    if (course?.lectures[fid] && course?.lectures[fid].ivqs) {
      if (
        course?.lectures[fid].ivqs.ques &&
        course?.lectures[fid].ivqs.ques.length > 0
      ) {
        let tempMap = {};
        for (var i = 0; i < course?.lectures[fid].ivqs.ques.length > 0; i++) {
          if (
            !(
              status.status &&
              status.status[fid] &&
              status.status[fid].vres_tim
            ) ||
            Math.floor(course?.lectures[fid].ivqs.cuetime[i].cue_time) >=
              status?.status[fid]?.vres_tim
          ) {
            tempMap[
              Math.floor(course?.lectures[fid].ivqs.cuetime[i].cue_time)
            ] = course?.lectures[fid].ivqs.ques[i];
          }
        }
        setTimeMap({ ...tempMap });
      }
    }
    var tempLectureData = course?.lectures[fid];
    console.log("lecture timing", { tempLectureData });
    if (tempLectureData && !tempLectureData.vlen) {
      await getLectureTime(tempLectureData._id, false);
    }
    if (
      tempLectureData &&
      tempLectureData.feedback &&
      tempLectureData.feedback_obj
    ) {
      setFeedbackLecture(tempLectureData.feedback_obj);
    }
    if (tempLectureData) {
      setDescriptionAsHtml(tempLectureData.dsc);
    }
    setLiveData(false);
    if (!tempLectureData) {
      setLiveData(true);
      for (var i = 0; i < course.livlec[chid].length; i++) {
        if (course.livlec[chid][i]._id == fid) {
          setLecture(course.livlec[chid][i]);
          tempLectureData = course.livlec[chid][i];
          if (
            tempLectureData &&
            tempLectureData.feedback &&
            tempLectureData.feedback_obj
          ) {
            setFeedbackLecture(tempLectureData.feedback_obj);
          }
          setDescriptionAsHtml(tempLectureData.dsc);
        }
      }
      console.log("live lecture timing", { tempLectureData });
      if (tempLectureData && !tempLectureData.vlen) {
        await getLectureTime(tempLectureData._id, true);
      }
      if (
        tempLectureData &&
        tempLectureData.recurl.includes("https://vimeo.com/")
      ) {
        setLectureUrl(tempLectureData.recurl);
        setVideoSource("vimeo");
      } else if (
        tempLectureData &&
        tempLectureData.recurl.includes("https://www.youtube.com/")
      ) {
        setLectureUrl(tempLectureData.recurl);
        setVideoSource("youtube");
      } else if (
        tempLectureData &&
        tempLectureData.recurl.includes("https://youto.be/")
      ) {
        setLectureUrl(tempLectureData.recurl);
        setVideoSource("youtube");
      }

      if (tempLectureData.pdf && tempLectureData.pdf.length > 0) {
        var tempResourceMap = {};
        for (var i = 0; i < tempLectureData.pdf.length; i++) {
          var tempResource = await getSignedUrl(
            pid,
            gid,
            course.others[tempLectureData.pdf[i]].url
          );
          if (tempResource.success) {
            tempResourceMap[tempLectureData.pdf[i]] =
              course.others[tempLectureData.pdf[i]];
            tempResourceMap[tempLectureData.pdf[i]]["signed_url"] =
              tempResource.data.signed_url;
          }
        }
        setResourceMap(tempResourceMap);
      }
    } else {
      if (
        tempLectureData.url &&
        tempLectureData.url.includes("https://vimeo.com/")
      ) {
        setLectureUrl(tempLectureData.url);
        setVideoSource("vimeo");
      } else if (
        tempLectureData.url &&
        tempLectureData.url.includes("https://www.youtube.com/")
      ) {
        setLectureUrl(tempLectureData.url);
        setVideoSource("youtube");
      } else if (
        tempLectureData.url &&
        tempLectureData.url.includes("https://youto.be/")
      ) {
        setLectureUrl(tempLectureData.url);
        setVideoSource("youtube");
      }
      // else if (tempLectureData.url.includes("b-cdn.net")) {
      //   setLectureUrl(tempLectureData.url);
      //   setVideoSource("b-cdn.net");
      // }

      if (tempLectureData.pdf && tempLectureData.pdf.length > 0) {
        var tempResourceMap = {};
        for (var i = 0; i < tempLectureData.pdf.length; i++) {
          var tempResource = await getSignedUrl(
            pid,
            gid,
            course.others[tempLectureData.pdf[i]].url
          );
          if (tempResource.success) {
            tempResourceMap[tempLectureData.pdf[i]] =
              course.others[tempLectureData.pdf[i]];
            tempResourceMap[tempLectureData.pdf[i]]["signed_url"] =
              tempResource.data.signed_url;
          }
        }
        setResourceMap(tempResourceMap);
      }
    }
    if (
      tempLectureData.feedback &&
      status.status[tempLectureData.feedback] &&
      status.status[tempLectureData.feedback].stts == 4
    ) {
      setIsFeedbackSubmitted(true);
      setFeedbackOpen(false);
    }
  };

  const updateLectureStatus = async (video, done, playedSeconds) => {
    var tmpTimeStamp = playedSeconds ? playedSeconds : 0;
    if (video) {
      await updateLecStatus(pid, cid, chid, fid, tmpTimeStamp, done);
    } else {
      await updateLivLecStatus(pid, cid, chid, fid, tmpTimeStamp, done);
    }
  };

  const downLoadResources = function (url, name) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Convert blob to Base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64Data = reader.result;
          console.log(base64Data); // Base64 representation of the image
          downloadBase64File(base64Data, name);
        };
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        setLoading(false);
      });
  };

  const progress = async (prg) => {
    console.log("prg.playedSeconds", prg.playedSeconds);
    playedSecondsRef.current = Math.floor(prg.playedSeconds);
    setPlayedSeconds(Math.floor(prg.playedSeconds));
    setPlayed(prg.played);
    let currentTime = Math.floor(prg.playedSeconds);
    let totalDuration = playerRef.current.getDuration();
    console.log({ totalDuration, currentTime });
    if (timeMap[currentTime]) {
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      console.log(timeMap[currentTime]);
      setPlaying(submittedIvq[timeMap[currentTime]._id]);
      setIVQShow(true);
      setQuesData({ ...timeMap[currentTime] });
    }
    setIntervalCall(Math.floor(currentTime / 5) * 5);
    console.log({ isFeedbackSubmitted, feedbackLecture, skippedFeedback });
    if (
      currentTime + 3 == Math.floor(totalDuration) &&
      !isFeedbackSubmitted &&
      feedbackLecture &&
      !skippedFeedback
    ) {
      console.log("inside feedback", { totalDuration, currentTime });

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      setPlaying(false);
      await openFeedback(feedbackLecture, fid);
    }
  };
  // const openFeedback = function (quiz, chapId) {
  //     setFeedbackTestId(quiz.tid)
  //     var currentQuiz = feedbackLecture
  //     if (!currentQuiz['completion']) {
  //       $scope.feedbackOpen = true
  //       $scope.currentFeedbackQuiz = $scope.feedbacks_lecture
  //       $scope.get_plugin_identifier(
  //         $scope.currentFeedbackQuiz,
  //         $stateParams.gid
  //       )
  //     } else {
  //       $scope.showAttempted = true
  //     }
  //   }

  const openAddBookmark = () => {
    setAddNewBk(true);
    setPlaying(false);
  };

  var convertSecondsToMinuteAndSeconds = function (tSeconds) {
    var minutes = Math.floor(tSeconds / 60);
    var seconds = (tSeconds % 60).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  const saveAddBookmark = async function (note) {
    const data = await updateLectureBookmark(fid, playedSeconds * 1000, note);
    await handleBookMark();
    if (data.success) {
      setAddNewBk(false);
      setBkmrkTextArea("");
    }
  };

  const closeAddBookmark = () => {
    setBkmrkTextArea("");
    setAddNewBk(false);
    setPlaying(true);
  };

  const bookmarkFilter = (e) => {
    let value = e.target.value;
    if (value == "clc") {
      setBkmrkFilter("clc");
      setLectureBookmark([...selectedLectureBookmark]);
    } else if (value == "alc") {
      setBkmrkFilter("alc");
      setLectureBookmark([...chapterBookmark]);
    }
  };

  const bookmarkSort = function (e) {
    let value = e.target.value;
    console.log({ value });
    var data = [...lectureBookmark];
    data.sort(function (a, b) {
      var first = new Date(a.updatedAt);
      var second = new Date(b.updatedAt);
      if (value === "smr") {
        setBkmrkSort("smr");
        if (first < second) {
          return 1;
        }
        if (first > second) {
          return -1;
        }
      } else if (value === "sbo") {
        setBkmrkSort("sbo");
        if (first < second) {
          return -1;
        }
        if (first > second) {
          return 1;
        }
      }
      return 0;
    });
    setLectureBookmark([...data]);
  };

  const handleBookMark = async function () {
    const lecBookmark = await getLectureBookmark(fid);
    const chapBookmark = await getChapterBookmark(cid);
    if (lecBookmark.success) {
      var data = lecBookmark.data || [];
      data.sort(function (a, b) {
        var first = new Date(a.updatedAt);
        var second = new Date(b.updatedAt);
        if (first < second) {
          return 1;
        }
        if (first > second) {
          return -1;
        }
        return 0;
      });
      setSelectedLectureBookmark(data);
      setLectureBookmark(lecBookmark.data || []);
    }
    if (chapBookmark.success) {
      var data = chapBookmark.data || [];
      data.sort(function (a, b) {
        var first = new Date(a.updatedAt);
        var second = new Date(b.updatedAt);
        if (first < second) {
          return 1;
        }
        if (first > second) {
          return -1;
        }
        return 0;
      });
      setChapterBookmark(data);
    }
    setAboutResources("Bookmark");
  };

  var convertMillisToMinuteAndSeconds = function (millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  const openDeleteBookmark = function (index) {
    // console.log("open delete bk" , $scope.openDeleteBkModal);
    window.scrollTo(0, 0);
    setSelectedBkIndex(index);
    setOpenDeleteBkModal(true);
  };

  const editBookmark = function (index) {
    // console.log("index :", index);
    setEditBk(true);
    setSelectedBkIndex(index);
    setPreviousBookmarkNote(lectureBookmark[index].note);
  };

  const saveEditBookmark = async function (index) {
    setEditBk(false);
    await updateLectureBookmark(
      fid,
      lectureBookmark[index].millis,
      lectureBookmark[index].note
    );
    await handleBookMark();
  };

  const closeEditBookmark = async function (index) {
    var data = [...lectureBookmark];
    data[index].note = previousBookmarkNote;
    setLectureBookmark([...data]);
    setEditBk(false);
    setSelectedBkIndex(-1);
  };

  const skipVideo = function (time) {
    var selected_time = time / 1000;
    console.log("seek to :", time);
    playerRef.current.seekTo(selected_time);
  };

  const handleRadioChange = (event) => {
    setSelected(event.target.value); // For radio buttons, set single selected value
  };

  const handleChangeCheckbox = (option, isChecked) => {
    let updatedSelected = selected ? [...selected] : []; // Copy selected options
    if (isChecked) {
      updatedSelected.push(option.id); // Add option ID if checked
    } else {
      const index = updatedSelected.indexOf(option.id);
      updatedSelected.splice(index, 1); // Remove option ID if unchecked
    }
    setSelected(updatedSelected);
  };

  const handleSubmitIvq = async function () {
    let data = { ...submittedIvq };
    let tempSelected = [];
    if (quesData.type == 1) {
      if (selected) {
        selected.forEach(function (opt) {
          tempSelected.push(opt.toString());
        });
      }
    } else {
      tempSelected = [selected ? selected.toString() : ""];
    }
    if (
      (tempSelected && tempSelected.length == 0) ||
      (tempSelected && tempSelected.length == 1 && tempSelected[0] == "")
    ) {
      window.alert("Please answer the question!");
      return;
    }
    const submitData = await submitIvq(quesData._id, tempSelected, fid, pid);
    if (submitData.success) {
      data[quesData._id] = true;
      setSubmittedIvq({ ...data });
      setIsCorrected(submitData.data.is_correct);
      displayExplanation(submitData.data);
      setIVQShow(false);
    }
  };

  var displayExplanation = function (correct_options) {
    setIVQShow(false);
    setSelected([]);
    let tempCorrect = [];
    if (quesData.type == 2) {
      tempCorrect.push({
        ans: correct_options.correct_answer[0].text,
        exp: correct_options.correct_answer[0].explanation,
      });
    } else {
      correct_options.correct_answer.forEach(function (correct_opt) {
        quesData.options.forEach(function (opt) {
          if (opt.id == correct_opt.text) {
            tempCorrect.push({
              ans: opt.text,
              exp: correct_opt.explanation,
            });
          }
        });
      });
    }
    setCorrectOpt([...tempCorrect]);
    setShowExplanation(true);
  };
  const onReady = () => {
    console.log("ss----", playerRef);
    let hlsPlayer = playerRef.current.getInternalPlayer("hls");
    hlsPlayer.subtitleTrack = 1;
    console.log(
      "hls.levels",
      hlsPlayer,
      hlsPlayer.subtitleTrackController,
      // hlsPlayer.allSubtitleTracks,
      hlsPlayer.subtitleDisplay,
      hlsPlayer.subtitleTrack, //subtitleTracks,
      hlsPlayer.subtitleTracks
    );

    if (status.status && status.status[fid] && status.status[fid].vres_tim) {
      if (timeSkip) {
        skipVideo(timeSkip);
      } else if (playerRef.current) {
        playerRef.current.seekTo(status.status[fid].vres_tim);
      }
    }
  };

  const handleKeyDown = (event) => {
    // playedSecondsRef.current = Math.floor(prg.playedSeconds);

    // console.log("hello", playerRef.current.getCurrentTime(),event.key, playedSecondsRef);
    if (event.key === "ArrowRight" && playerRef.current) {
      let move2secforward = playedSecondsRef.current + 5;
      playedSecondsRef.current = playedSecondsRef.current + 5;
      playerRef.current.seekTo(move2secforward);
    } else if (event.key === "ArrowLeft" && playerRef.current) {
      let move2secback = playedSecondsRef.current - 5;
      playedSecondsRef.current = playedSecondsRef.current - 5;

      playerRef.current.seekTo(move2secback);
    }
  };

  const ContinueVideo = function () {
    setFeedbackOpen(false);
    setSkippedFeedback(true);
    setPlaying(true);
  };

  let onChangeBitrate = (id, resolution) => {
    console.log({ id });
    let hlsPlayer = playerRef.current.getInternalPlayer("hls");
    hlsPlayer.currentLevel = Number(id);
    console.log(
      "hls.levels",
      hlsPlayer.levels,
      hlsPlayer.currentLevel,
      hlsPlayer.nextLevel
    );
    setToggle(!toggle);
    setSelectedResolution(resolution);
  };

  const selectOption = function (section, ques, ans, index) {
    console.log("------------inside select option", ques, ans, index);
    if (ques.question.type == 4) {
      ans.index = index;
      ques.question.answer = ans;
    }
    ques.question.answer = ans;

    console.log(ques.question.answer);
    // if (ques.question.type == 4) {
    //   $scope.Average_rating += Math.round(
    //     ans.opt_point / ques.question.ans_options.length
    //   )
    // }
    // console.log({ section: section, ques: ques, ans: ans })
    console.log({ feedbackSections: feedbackSections });
    let tempMap = { ...answerOptionMap };
    tempMap[ques.question._id] = index;
    setAnswerOptionMap({ ...tempMap });
  };

  const handleSubjective = async function (e, question) {
    let tempAnswerMap = { ...subjectiveFeedbackAnswerMap };
    tempAnswerMap[question._id] = e.target.value;
    setSubjectiveFeedbackAnswerMap(() => {
      return { ...tempAnswerMap };
    });
  };

  const checkAllFilled = function () {
    var questions = [];
    var filled = true;
    for (var i = 0; i < feedbackSections.length; i++) {
      var section = feedbackSections[i];
      // console.log({section:section})
      for (var j = 0; j < section.ques.length; j++) {
        var ques = section.ques[j];
        console.log({ ques: ques });
        if (ques.question.type != 3) {
          if (
            !ques ||
            !ques.question ||
            (!("answer" in ques.question) && ques.question.compulsory) ||
            ((ques.question.answer == null || ques.question.answer == "") &&
              ques.question.compulsory)
          ) {
            filled = false;
          }
        } else {
          if (
            ques.question.type == 3 &&
            (!subjectiveFeedbackAnswerMap[ques.question._id] ||
              subjectiveFeedbackAnswerMap[ques.question._id] == "") &&
            ques.question.compulsory
          ) {
            console.log({ subjectiveFeedbackAnswerMap });
            filled = false;
          }
        }
      }
    }
    return filled;
  };

  const deleteBookmark = async function () {
    setLoading(true);
    const deleteBkData = await deleteLectureBookmark(
      lectureBookmark[selectedBkIndex].lec_id,
      lectureBookmark[selectedBkIndex]._id
    );
    if (deleteBkData.success) {
      setLoading(false);
      window.alert("Successfully deleted bookmark!");
      await handleBookMark();
      setOpenDeleteBkModal(false);
      document.body.style.overflow = "auto";
    } else {
      setLoading(false);
      window.alert("Something Went Wrong!");
    }
  };

  return !loading ? (
    isLockedDeadline ? (
      <div className="lectureScss">
        <div className="as-dl-msg">The Content deadline is exceeded!</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "200px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              top: "-80px",
              position: "relative",
              justifyContent: "center",
            }}
          >
            <img
              src="https://cdn.pegasus.imarticus.org/chapter-controller/deadlineExpired.svg"
              alt="Deadline Expired"
            />
          </div>
        </div>
        <div
          style={{
            color: "#e94b35",
            fontSize: "40px",
            fontWeight: "800",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: "-20px",
          }}
        >
          Deadline Expired at {deadline}
        </div>
      </div>
    ) : (
      <>
        {lectureUrl || videoInqueStatus ? (
          <div style={{ margin: "2.5%" }} className="lectureScss">
            {openDeleteBkModal && (
              <div style={{ margin: "0 auto", textAlign: "center" }}>
                <div className="bkmrk-dlt"></div>
                <div
                  style={{ margin: "0 auto", textAlign: "center" }}
                  className="bkmrk-dlt-inr"
                >
                  <div
                    style={{ margin: "0 auto", textAlign: "center" }}
                    className="bkmrk-dlt-inr-2"
                  >
                    <div style={{ margin: "0 auto", textAlign: "center" }}>
                      <img
                        className="bkmrk-dlt-img"
                        src="https://cdn.pegasus.imarticus.org/chapter-controller/Red-delete.svg"
                        alt="delete-icon"
                      />
                      <div
                        style={{ margin: "0 auto", textAlign: "center" }}
                        className="bkmrk-dlt-txt"
                      >
                        Delete
                      </div>
                      <div
                        style={{
                          margin: "12px auto",
                          textAlign: "center",
                          color: "black",
                        }}
                        className="bkmrk-dlt-txt-cnf"
                      >
                        Are you sure, you want to delete this bookmark?
                      </div>
                      <div
                        style={{
                          margin: "0 auto",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          columnGap: "2%",
                        }}
                        className="bkmrk-dlt-btn"
                      >
                        <button
                          className="bkmrk-dlt-cncl btn"
                          onClick={() => {
                            setOpenDeleteBkModal(false);
                            document.body.style.overflow = "auto";
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="bkmrk-dlt-dl btn btn-primary"
                          onClick={deleteBookmark}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!feedbackOpen && !openDeleteBkModal && (
              <div>
                {videoInqueStatus ? (
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "5%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      backgroundColor: "#263238",
                    }}
                  >
                    <div>
                      <p className="resume-test">Please wait...</p>
                    </div>
                    <div>
                      {" "}
                      <img
                        src={
                          "https://cdn.pegasus.imarticus.org/feedback/resume.svg"
                        }
                        alt="png"
                      />
                    </div>

                    <div>
                      {" "}
                      <p className="resume-test">{bunnyUploadingText}</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* style={{ position: "relative", paddingTop: "56.25%" */}
                    {/* <iframe
                  src={lectureUrl}
                  loading="lazy"
                  style={{
                    border: "0",
                    position: "absolute",
                    top: "0",
                    height: "100%",
                    width: "100%",
                  }}
                  allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                  allowfullscreen="true"
                  ref={playerRef}
                  playing={playing}
                  onPlay={() => {
                    setPlaying(true);
                  }}
                  onProgress={progress}
                  onReady={onReady}
                  controls={true}
                ></iframe> */}
                    {lectureUrl && (
                      <ReactPlayer
                        width="100%"
                        ref={playerRef}
                        playing={playing}
                        controls={true}
                        onPlay={() => {
                          setPlaying(true);
                        }}
                        onKeyDown={handleKeyDown}
                        height="700px"
                        url={lectureUrl}
                        onProgress={progress}
                        onReady={onReady}
                        forceHLS={true}
                        enableCaptions={true}
                        selectedLanguage="en"
                        config={{
                          file: {
                            attributes: {
                              crossOrigin: "anonymous",
                            },
                            hlsOptions: {
                              enableCaptions: true,
                            },
                            tracks: [
                              {
                                kind: "subtitles",
                                src: `https://${CONFIGS.BUNNY_PULL_ZONE}/${videoId}/captions/en.vtt`,
                                srcLang: "en",
                                default: true,
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            {feedbackLecture && feedbackOpen && !isFeedbackSubmitted && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgb(1, 32, 1)",
                }}
              >
                <div>
                  <p
                    style={{
                      color: "white",
                      fontFamily: "Source Sans Pro",
                      textAlign: "center",
                      fontWeight: 700,
                      fontSize: 28,
                      marginTop: "15%",
                    }}
                  >
                    We’d welcome your feedback!
                  </p>
                </div>
                {feedbackSections.map((section, sectionIndex) => (
                  <div key={sectionIndex} value={sectionIndex}>
                    {section.ques.map((ques, quesIndex) => (
                      <div
                        key={quesIndex}
                        className="feedbackQues"
                        value={quesIndex}
                      >
                        <p
                          className="quesLabel"
                          style={{
                            color: "white",
                            fontFamily: "Source Sans Pro",
                            textAlign:
                              ques.question.type === 4 ? "center" : "left",
                            fontWeight: 400,
                            fontSize: 20,
                            margin: "0px 4%",
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              ques.question.text +
                              (ques.question.compulsory ? " *" : ""),
                          }}
                        />
                        <div style={{ margin: "0 8%", marginTop: "2%" }}>
                          {ques.question.type === 3 && (
                            <textarea
                              value={
                                subjectiveFeedbackAnswerMap[
                                  ques.question._id
                                ] || ""
                              }
                              onChange={(e) => {
                                handleSubjective(e, ques.question);
                              }}
                              style={{
                                margin: "auto",
                                width: 450,
                                height: 90,
                                backgroundColor: "white",
                                marginBottom: "5%",
                              }}
                            />
                          )}
                          {ques.question.type === 1 && (
                            <form>
                              <div>
                                {ques.question.ans_options.map(
                                  (ans, ansIndex) => (
                                    <div key={ansIndex} className="answers">
                                      <input
                                        type="radio"
                                        name={`'answer'${ques._id}`}
                                        onClick={() =>
                                          selectOption(
                                            section,
                                            ques,
                                            ans,
                                            ansIndex
                                          )
                                        }
                                        style={{
                                          color: "white",
                                          width: 20,
                                          height: 20,
                                        }}
                                      />
                                      <label
                                        style={{
                                          color: "white",
                                          fontWeight: 400,
                                          fontSize: 20,
                                          padding: 5,
                                          margin: 2,
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: ans.text,
                                        }}
                                      />
                                    </div>
                                  )
                                )}
                                <br />
                              </div>
                            </form>
                          )}
                          {ques.question.type === 4 && (
                            <form>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "flex-start",
                                  justifyContent: "center",
                                }}
                              >
                                {ques.question.ans_options.map(
                                  (ans, ansIndex) => (
                                    <div
                                      key={ansIndex}
                                      className="answers"
                                      style={{
                                        borderBottom:
                                          ansIndex >
                                          answerOptionMap[ques.question._id]
                                            ? "0"
                                            : "4px solid #28a745",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          cursor: "pointer",
                                          rowGap: 8,
                                        }}
                                      >
                                        <div
                                          onClick={() =>
                                            selectOption(
                                              section,
                                              ques,
                                              ans,
                                              ansIndex
                                            )
                                          }
                                          style={{ height: 64, width: 64 }}
                                        >
                                          <img
                                            src={
                                              ansIndex >
                                              answerOptionMap[ques.question._id]
                                                ? "https://cdn.pegasus.imarticus.org/feedback/trans_parent_star.svg"
                                                : "https://cdn.pegasus.imarticus.org/feedback/yellow.svg"
                                            }
                                            alt="png"
                                            style={{ width: 64, height: 64 }}
                                          />
                                        </div>
                                        <div>
                                          <label
                                            style={{
                                              color: "white",
                                              fontFamily: "Source Sans Pro",
                                              textAlign: "center",
                                              fontWeight: 400,
                                              fontSize: 14,
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: ans.opt_txt,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <br />
                            </form>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "2%",
                  }}
                >
                  {!lecture.feedback_compulsory && (
                    <div style={{ margin: "10px" }}>
                      <div className="submitBar">
                        <button
                          role="submit"
                          className="submit"
                          style={{
                            color: "white",
                            fontFamily: "Source Sans Pro",
                            textAlign: "center",
                            borderColor: "white",
                            backgroundColor: "rgb(1, 32, 1)",
                            fontWeight: 600,
                            fontSize: 18,
                            margin: "5px",
                            borderRadius: "10px",
                            padding: "10px",
                            border: "0.2px solid",
                            width: "200px",
                          }}
                          onClick={ContinueVideo}
                        >
                          Skip
                        </button>
                      </div>
                    </div>
                  )}
                  <div style={{ margin: "10px" }}>
                    <div className="submitBar">
                      <button
                        role="submit"
                        className="submit"
                        disabled={feedbackSections.length === 0}
                        style={{
                          color: "#3C4852",
                          backgroundColor: "white",
                          fontFamily: "Source Sans Pro",
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 18,
                          borderRadius: "10px",
                          padding: "10px",
                          border: "none",
                          width: "200px",
                        }}
                        onClick={submitFeedback}
                      >
                        Submit Feedback
                      </button>
                    </div>
                  </div>
                </div>
                {feedbackLecture && !feedbackOpen && (
                  <div>
                    <p
                      style={{
                        color: "white",
                        fontFamily: "Source Sans Pro",
                        textAlign: "center",
                        fontWeight: 300,
                        fontSize: 20,
                      }}
                    >
                      It only takes a few seconds, once completed you can move
                      to the next content
                    </p>
                  </div>
                )}
              </div>
            )}
            {isFeedbackSubmitted && feedbackOpen && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "10%",
                  justifyContent: "center",
                  backgroundColor: "rgb(1, 32, 1)",
                  marginTop: "10%",
                }}
              >
                <div>
                  <img
                    style={{ height: "200px", width: "200px", marginTop: "8%" }}
                    src="https://cdn.pegasus.imarticus.org/feedback/Group.svg"
                    alt="png"
                  />
                </div>
                <div>
                  <p
                    style={{
                      color: "white",
                      fontFamily: "Source Sans Pro",
                      textAlign: "center",
                      fontWeight: 400,
                      fontSize: "40px",
                      marginTop: "5%",
                    }}
                  >
                    Success!
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      color: "white",
                      fontFamily: "Source Sans Pro",
                      textAlign: "center",
                      fontWeight: 700,
                      fontSize: "28px",
                      margin: "5%",
                    }}
                  >
                    {feedbackResponse[averageRating - 1] &&
                    feedbackResponse[averageRating - 1].length > 0
                      ? feedbackResponse[averageRating - 1].DESC
                      : "Thank You for your feedback"}
                  </p>
                </div>
                <div>
                  <button
                    role="submit"
                    className="submit"
                    style={{
                      color: "#3C4852",
                      backgroundColor: "white",
                      fontFamily: "Source Sans Pro",
                      textAlign: "center",
                      fontWeight: 600,
                      fontSize: "18px",
                      borderRadius: "10px",
                      padding: "10px",
                      width: "200px",
                      marginBottom: "8%",
                    }}
                    onClick={ContinueVideo}
                  >
                    Done
                  </button>
                </div>
              </div>
            )}

            <div class="bookmarkButton">
              {videoSource == "b-cdn.net" && (
                <div class="bookmarkButton">
                  <div class="dropdown">
                    <button
                      class="btn btn-outline-primary dropdown-toggle addBookmarkButton"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={handletoggle}
                      style={{ width: "210px" }}
                    >
                      {selectedResolution
                        ? selectedResolution
                        : "Choose Video Quality"}
                    </button>
                    <div
                      className="profile-dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                      // style={{position:"relative"}}
                    >
                      {toggle && (
                        <a
                          className="dropdown-item"
                          // href="#"
                          key={-1}
                          onClick={() => onChangeBitrate(-1, "Auto")}
                          value={-1}
                        >
                          Auto
                        </a>
                      )}
                      {toggle &&
                        playerRef.current
                          ?.getInternalPlayer("hls")
                          ?.levels.map((level, id) => (
                            <a
                              className="dropdown-item"
                              // href="#"
                              value={id}
                              key={id}
                              onClick={() =>
                                onChangeBitrate(id, `${level.height}p`)
                              }
                            >
                              {level.height}p
                            </a>
                          ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="bookmarkButton">
                <button
                  className="btn btn-outline-primary addBookmarkButton"
                  onClick={openAddBookmark}
                >
                  <img
                    src="https://cdn.pegasus.imarticus.org/index/bookmark_neutral.svg"
                    className="bookmarkImage"
                    style={{
                      color: "#055646",
                      backgroundColor: "transparent !important",
                    }}
                  />
                  Bookmark
                </button>
              </div>
            </div>

            <div>
              {addNewBk && (
                <div className="bkmrk-new-bk">
                  <div className="bkmrk-tmstmp">
                    <span className="bkmrk-tmstmp-act">
                      {convertSecondsToMinuteAndSeconds(playedSeconds)}
                    </span>
                  </div>
                  <div className="bkmrk-txt">
                    <textarea
                      className="bkmrk-txt-ar"
                      value={bkmrkTextArea}
                      onChange={(e) => setBkmrkTextArea(e.target.value)}
                    ></textarea>
                    <button
                      className="bkmrk-btn btn"
                      onClick={() => saveAddBookmark(bkmrkTextArea)}
                    >
                      Save
                    </button>
                    <button
                      className="bkmrk-btn btn btn-outline-primary"
                      onClick={() => closeAddBookmark()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="video-desc">
              <div
                className="nav-tabs"
                style={{ display: "flex", columnGap: "30px", flexWrap: "wrap" }}
              >
                <div
                  className="eck-tabss"
                  onClick={() => {
                    setAboutResources("About");
                  }}
                  style={{
                    cursor: "pointer",
                    marginLeft: "15px",
                    color: "#3C4852",
                    padding: "8px 16px 13px 16px",
                    borderBottom:
                      aboutResources == "About" ? "3px solid #3C4852" : "",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  About
                </div>
                <div
                  className="eck-tabss"
                  onClick={() => {
                    setAboutResources("Resources");
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#3C4852",
                    padding: "8px 16px 13px 16px",
                    borderBottom:
                      aboutResources == "Resources" ? "3px solid #3C4852" : "",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Resources
                </div>
                <div
                  className="eck-tabss"
                  onClick={handleBookMark}
                  style={{
                    cursor: "pointer",
                    color: "#3C4852",
                    padding: "8px 16px 13px 16px",
                    borderBottom:
                      aboutResources == "Bookmark" ? "3px solid #3C4852" : "",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Bookmark
                </div>
              </div>
              <div>
                {aboutResources == "About" && (
                  <div
                    style={{
                      color: "#494949",
                      fontSize: "16px",
                      padding: "30px 0px",
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: lecture.dsc }}></p>
                  </div>
                )}
                {aboutResources == "Resources" && (
                  <div
                    style={{
                      color: "#494949",
                      fontSize: "16px",
                      padding: "30px 0px",
                    }}
                    id="resources"
                    className="tab-pane"
                  >
                    <ul className="docLectureResouceList">
                      {lecture.pdf.map((extra, index) => (
                        <li key={index} className="docLectureResouceListItem">
                          {course.others[extra].url && (
                            <div
                              className="docLectureResouceListItemLink"
                              onClick={() => {
                                setLoading(true);
                                downLoadResources(
                                  resourceMap[extra].signed_url,
                                  course.others[extra].nm
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Download{" "}
                              {lecture.nm == course.others[extra].nm
                                ? `Resource ${lecture.nm}`
                                : course.others[extra].nm}
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {aboutResources == "Bookmark" && (
                  <div
                    style={{
                      color: "#494949",
                      fontSize: "16px",
                      padding: "30px 0px",
                    }}
                    className="tab-pane"
                  >
                    <ul className="bkmrk-filter">
                      <li>
                        <div className="bkmrk-filter-lc">
                          <select
                            className="form-control bkmrk-flt"
                            style={{ cursor: "pointer" }}
                            value={bkmrkFilter}
                            onChange={bookmarkFilter}
                          >
                            <option value="clc">Current Lectures</option>
                            <option value="alc">All Lectures</option>
                          </select>
                        </div>
                        <div className="bkmrk-filter-srt">
                          <select
                            className="form-control bkmrk-flt"
                            style={{ cursor: "pointer" }}
                            value={bkmrkSort}
                            onChange={bookmarkSort}
                          >
                            <option value="smr">Sort by most recent</option>
                            <option value="sbo">Sort by oldest</option>
                          </select>
                        </div>
                      </li>
                    </ul>
                    <ul>
                      {lectureBookmark.length > 0 ? (
                        lectureBookmark.map((bk, index) => (
                          <li key={index} className="bkmrk-main">
                            <div className="bkmrk-tmstmp">
                              {bk.ch_name === undefined && (
                                <span
                                  className="bkmrk-tmstmp-act"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => skipVideo(bk.millis)}
                                >
                                  {convertMillisToMinuteAndSeconds(bk.millis)}
                                </span>
                              )}
                              {bk.ch_name !== undefined &&
                                bk.lec_id === fid && (
                                  <span
                                    className="bkmrk-tmstmp-act"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => skipVideo(bk.millis)}
                                  >
                                    {convertMillisToMinuteAndSeconds(bk.millis)}
                                  </span>
                                )}
                              {bk.ch_name !== undefined &&
                                bk.lec_id !== fid && (
                                  <a
                                    href={`${CONFIGS.WEB_DASHBOARD_BASE_URL}chapter/${pid}/${gid}/${cid}/${levelId}/lecture/${bk.ch_id}/${bk.lec_id}/${bk.millis}`}
                                  >
                                    <span className="bkmrk-tmstmp-act">
                                      {convertMillisToMinuteAndSeconds(
                                        bk.millis
                                      )}
                                    </span>
                                  </a>
                                )}
                            </div>
                            <div className="bkmrk-txt bkmrk-dt">
                              <div className="bkmrk-dt-head">
                                {bk.lec_name}
                                <span className="bkmrk-dt-ch">
                                  {bk.ch_name ? " - " + bk.ch_name : ""}
                                </span>
                                <span
                                  className="bkmrk-dt-delete"
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/chapter-controller/delete.svg"
                                    onClick={() => openDeleteBookmark(index)}
                                  />
                                </span>
                                <span
                                  className="bkmrk-dt-edit"
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/chapter-controller/4.svg"
                                    onClick={() => editBookmark(index)}
                                  />
                                </span>
                              </div>
                              {!editBk && (
                                <div className="bkmrk-dt-main">
                                  <pre>{bk.note}</pre>
                                </div>
                              )}
                              {selectedBkIndex === index && editBk && (
                                <div className="bkmrk-txt">
                                  <textarea
                                    className="bkmrk-txt-ar"
                                    value={lectureBookmark[index].note}
                                    onChange={(e) => {
                                      var data = [...lectureBookmark];
                                      data[index].note = e.target.value;
                                      setLectureBookmark([...data]);
                                    }}
                                  ></textarea>
                                  <button
                                    className="bkmrk-btn btn btn-outline-primary"
                                    onClick={() => saveEditBookmark(index)}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="bkmrk-btn btn btn-outline-primary"
                                    onClick={() => {
                                      closeEditBookmark(index);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              )}
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>
                          <div
                            style={{ margin: "0 auto", textAlign: "center" }}
                          >
                            <img
                              src="https://cdn.pegasus.imarticus.org/chapter-controller/empty-state.svg"
                              alt="Empty State"
                            />
                            <br />
                            <span className="bkmrk-no-bk">
                              No bookmarks yet
                            </span>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {IVQShow && !submittedIvq[quesData._id] && (
              <div
                className="uk-overlay uk-position-cover ivq-overlay-background"
                id="overlay"
                style={{
                  backgroundColor: "rgb(1, 32, 1)",
                  left: "0px",
                  padding: "2%",
                }}
              >
                <div
                  className="uk-overlay uk-position-bottom uk-animation-fade ivq-overlay"
                  style={{ wordBreak: "break-all", overflowY: "auto" }}
                >
                  <div style={{ height: "100%" }}>
                    <div
                      id="top-scroll"
                      style={{
                        height: "90%",
                        padding: "30px",
                        overflowY: "auto",
                        display: "table",
                        width: "100%",
                      }}
                    >
                      <div className="ivq-vc">
                        <h2 className="ivq-qus" style={{ textAlign: "left" }}>
                          Question: {quesData.text}
                        </h2>
                        {quesData.type === 0 && (
                          <div
                            className="uk-form-controls uk-form-controls-text mb10"
                            style={{ marginLeft: "30px" }}
                          >
                            {quesData.options.map((option) => (
                              <div>
                                <label key={option.id} className="ivq-label">
                                  <input
                                    className="uk-radio mt0 ivq-outline"
                                    type="radio"
                                    name="radio-button"
                                    value={option.id}
                                    checked={selected == option.id} // Set radio button checked state
                                    onChange={handleRadioChange}
                                  />
                                  &nbsp;&nbsp;{option.text}
                                </label>
                              </div>
                            ))}
                            <br />
                          </div>
                        )}
                        {quesData.type === 2 && (
                          <div
                            className="uk-form-controls uk-form-controls-text mb10 ivq-c"
                            style={{ textAlign: "left" }}
                          >
                            <input
                              className="uk-input ivq-input ivq-fill-In"
                              style={{ textAlign: "left" }}
                              id="form-horizontal-text"
                              type="text"
                              placeholder="Write here..."
                              value={selected} // Set text input value
                              onChange={(event) =>
                                setSelected(event.target.value)
                              }
                            />
                          </div>
                        )}
                        {quesData.type === 1 && (
                          <div
                            className="uk-form-controls uk-form-controls-text mb10"
                            style={{ marginLeft: "30px" }}
                          >
                            {quesData.options.map((option) => (
                              <div>
                                <label key={option.id} className="ivq-label">
                                  <input
                                    className="uk-checkbox mt0 ivq-outline"
                                    type="checkbox"
                                    value={option.id || ""}
                                    checked={
                                      selected && selected.includes(option.id)
                                    } // Check if option ID is in selected array
                                    onChange={(event) =>
                                      handleChangeCheckbox(
                                        option,
                                        event.target.checked
                                      )
                                    }
                                  />
                                  &nbsp;&nbsp;{option.text}
                                </label>
                              </div>
                            ))}
                            <br />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <button
                        className="uk-button material-button-raised"
                        onClick={handleSubmitIvq}
                        style={{
                          float: "right",
                          marginRight: "5%",
                          width: "135px",
                          bottom: "56px",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showExplanation && (
              <div
                id="overlay"
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  left: "0px",
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  id="feedback-dialog"
                  className="is-open"
                  style={{
                    position: "fixed",
                    backgroundColor: "white",
                    padding: "2%",
                    width: "50%",
                    maxHeight: "100%",
                  }}
                >
                  <div className="md-dialog-content" style={{ padding: "2%" }}>
                    <div
                      style={{
                        margin: "0 auto",
                        textAlign: "center",
                        paddingTop: "30px",
                      }}
                    >
                      {isCorrected && (
                        <img
                          src="https://cdn.pegasus.imarticus.org/chapter-controller/correct.svg"
                          alt="Correct Answer"
                          className="correct"
                          style={{ width: "15%" }}
                        />
                      )}
                      {!isCorrected && (
                        <img
                          src="https://cdn.pegasus.imarticus.org/chapter-controller/incorrect.svg"
                          alt="Incorrect Answer"
                          className="incorrect"
                          style={{ width: "15%" }}
                        />
                      )}
                      <p
                        className={`${
                          isCorrected ? "ivq-correct" : "ivq-incorrect"
                        }`}
                      >
                        {isCorrected ? "Correct Answer" : "Incorrect Answer"}
                      </p>
                    </div>
                    <hr className="ivq-hr" />
                    <div style={{ overflowY: "auto", maxHeight: "250px" }}>
                      {correctOpt.map((option) => (
                        <dl
                          key={option.ans}
                          style={{
                            margin: "0 auto",
                            textAlign: "left",
                            padding: "10px 30px",
                          }}
                        >
                          <dt
                            className="ivq-label"
                            style={{
                              fontSize: "17px",
                              color: "#3C4852",
                              marginBottom: "8px",
                            }}
                          >
                            Correct Answer: {option.ans}
                          </dt>
                          <dd className="ivq-label" style={{ color: "black" }}>
                            Explanation :{" "}
                            {option.exp || "No explanation provided."}
                          </dd>
                        </dl>
                      ))}
                    </div>
                    <div className="center">
                      <button
                        className="uk-button material-button-raised ivq-button uk-modal-close continueButton"
                        style={{
                          width: "135px",
                          background: "white",
                          border: "2px solid var(--themeColor)",
                        }}
                        onClick={() => {
                          document.body.style.overflow = "auto";
                          setShowExplanation(false);
                          setPlaying(true);
                          setIVQShow(false);
                        }}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </>
    )
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = { dispatchIndividualBrand, getProfileInfoOnly };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Lecture)
);
